const scripts = {
  "async-varieties": () => import("./asyncScripts/varieties"),
};

export function asyncScripts() {
  for (const [divId, script] of Object.entries(scripts)) {
    const div = document.getElementById(divId);
    if (div) {
      script().then(s => s.main());
    }
  }
}
